<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="box2">
          <a href="http://www.subkamolplus.com/"
            >Thai<img :src="importedImg12" alt="" class="imgItem1"
          /></a>
        </div>
      </div>
    </div>
    <div class="imgBox10 min-w-1400">
      <div class="img10 max-w-1400">
        <img :src="importedImg13" :alt="altText" class="imgItem10" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox8 min-w-1400">
      <div class="img8 max-w-1400">
        <img :src="importedImg11" :alt="altText" class="imgItem8" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>

    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <div class="image-row">
          <router-link to="/" tag="li" class="a1">
            <a href=""><img :src="importedImg6" alt="" /></a>
          </router-link>
          <router-link to="/about" tag="li" class="a2">
            <a href=""><img :src="importedImg8" alt="" /></a>
          </router-link>
          <router-link to="/questions" tag="li" class="a3">
            <a href=""><img :src="importedImg9" alt="" /></a>
          </router-link>
          <!-- <router-link to="/protocol" tag="li" class="a4">
            <a href=""><img :src="importedImg10" alt="" /></a>
          </router-link> -->
        </div>
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <div class="titleContainer1">
            <p>
              Công ty có vị trí tốt và có thể<br />
              vay tiền nhanh chóng.
            </p>
            <div class="subtitles">
              <ul class="ul1">
                <li>SUBKAMOL LEASING COMPANY LIMITED</li>
                <li>
                  160/251 Saeng Chooto Road 71000 <br />
                  MUEANG KANCHANABURI
                </li>
              </ul>
            </div>
          </div>

          <div class="titleContainer2">
            <p>Số liên lạc</p>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <li tag="li" class="triangle">+66 832834932</li>
                <li tag="li" class="triangle">office@subkamolplus.com</li>
              </ul>
            </div>
          </div>

          <div class="titleContainer3">
            <p>Giờ kinh doanh</p>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul3">
                <li tag="li" class="triangle">
                  Thứ Hai đến Thứ Sáu, 8:30 sáng<br />
                  đến 5:00 chiều.
                </li>
                <li tag="li" class="triangle">
                  Thứ bảy 9:00 sáng - 4:30 chiều (mở<br />
                  cửa thứ bảy thứ hai và cuối cùng của <br />mỗi tháng)
                </li>
              </ul>
            </div>
          </div>
          <div class="titleContainer4">
            <p>Về chúng tôi</p>
            <!-- 第四个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul4">
                <li tag="li" class="triangle">Chính sách bảo mật</li>
                <li tag="li" class="triangle">Giải thưởng và thành tựu</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="imgBox7 min-w-1400">
      <div class="img7 max-w-1400">
        <img :src="importedImg7" :alt="altText" class="imgItem7" />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/subkamollending/components/img/1_1.png";
import importedImg2 from "@/page/subkamollending/components/img/1_2.png";
import importedImg3 from "@/page/subkamollending/components/img/1_3.png";
import importedImg4 from "@/page/subkamollending/components/img/1_4.png";
import importedImg5 from "@/page/subkamollending/components/img/1_5.png";
import importedImg6 from "@/page/subkamollending/components/img/btn1.png";
import importedImg7 from "@/page/subkamollending/components/img/footer.png";
import importedImg8 from "@/page/subkamollending/components/img/btn2.png";
import importedImg9 from "@/page/subkamollending/components/img/btn3.png";
import importedImg10 from "@/page/subkamollending/components/img/btn4.png";
import importedImg11 from "@/page/subkamollending/components/img/1_6.png";
import importedImg12 from "@/page/subkamollending/components/img/logo.png";
import importedImg13 from "@/page/subkamollending/components/img/1_7.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      importedImg13: importedImg13,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
/* .imgBox1 {
  margin-top: 100px;
} */
.imgBox1,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox10 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2,
.imgBox3,
.imgBox8 {
  display: flex;
  justify-content: center;
  width: 100%;
}

img {
  display: block;
}
.img1,
.img4,
.img5,
.img6,
.img7,
.img10 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2,
.img3 {
  margin-left: 45px;
  margin-right: 45px;
}
.img8 {
  margin-left: -1000px;
}
.imgItem1,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem10 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2,
.imgItem3,
.imgItem8 {
  max-width: 100%;
}
.imgItem2 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgItem3 {
  margin-bottom: 100px;
}
.imgItem8 {
  margin-bottom: 100px;
}

.imgItem8 {
  max-width: 100%;
}
.imgBox8 {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 210px;
}
.img8 {
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
}
.imgItem8 {
  max-width: 100%;
  margin-left: 550px;
}
.imgBox6 {
  display: flex;
  justify-content: center;
  background-color: #272c33;
  height: 460px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
  margin-top: 50px;
}

.titleContainer1 {
  width: 200px;
  position: absolute;
  margin-top: 30px;
  margin-left: 130px;
}
.titleContainer2 {
  margin-top: 30px;
  width: 300px;
  padding-left: 440px;
}
.titleContainer3 {
  margin-top: 30px;
  margin-left: 280px;
  width: 300px;
}
.titleContainer4 {
  margin-top: 30px;
  margin-left: 40px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
p {
  color: #ffffff;
  font-size: 16px;
  width: 400px;
}
.Li2 {
  width: 400px;
}
.ul1 li {
  width: 320px;
  padding-top: 10px;
}
.ul2 {
  width: 220px;
}
.ul2 li {
  width: 220px;
  padding-top: 10px;
}
.ul3 {
  width: 220px;
}
.ul3 li {
  width: 270px;
  padding-top: 10px;
}
.ul4 {
  width: 220px;
}
.ul4 li {
  width: 220px;
  padding-top: 10px;
}
.subtitles ul li.triangle::before {
  content: "";
  display: inline-block;
  width: 10px; /* 调整圆点的直径 */
  height: 10px; /* 调整圆点的直径 */
  border-radius: 50%; /* 使其成为圆形 */
  background-color: #ffffff; /* 设置圆点的颜色 */
  margin-right: 10px; /* 图标与文字之间的间距 */
}

.triangle a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}

li {
  font-size: 14px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 13px;
}
.image-row {
  display: flex;
  flex-direction: row; /* 设置水平方向排列 */
}
.a1,
.a2,
.a3,
.a4 {
  width: 250px;
  margin-top: 60px;
}
.a1 {
  margin-left: 180px;
}
.a2 {
  margin-left: 160px;
}
.a3 {
  margin-left: 160px;
}
a > img {
  width: 100%;
}
.imgBox1{
    position: relative;

}
.logo2 {
  margin-top: -900px;
}
.box2 {
  position: absolute;
  margin-top: -520px;
  margin-left: 200px;
}
.box2 a {
  display: flex;
  color: #fff;
}
.box2 img {
  padding-left: 10px;
}
</style>
